<template>
  <div class="page-wrapper">
    <div class="page-form change-password-form">
      <form @submit.prevent="save">
        <b-row>
          <Logo clickable></Logo>
        </b-row>
        <b-row>
          <span class="info-text mt-4 mb-4"
            >Please introduce your new password below, then press Reset Password
            button to reset your password.</span
          >
          <b-form-group class="custom-field-wrapper">
            <label class="custom-label">New Password</label>
            <b-form-input
              :class="{ 'validation-error': $v.form.password.$error }"
              :state="validateState('password')"
              v-model="$v.form.password.$model"
              type="password"
              class="custom-field custom-field-gray-bg"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.password.required">Password must not be empty.</b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.form.password.pattern">
              Password must contain: uppercase letter, lowercase letter,
              number, special character and be at least 8 characters
              long.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="custom-field-wrapper">
            <label class="custom-label">Confirm New Password</label>
            <b-form-input
              :class="{ 'validation-error': $v.form.confirmPassword.$error }"
              :state="validateState('confirmPassword')"
              type="password"
              v-model="$v.form.confirmPassword.$model"
              class="custom-field custom-field-gray-bg"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.confirmPassword.required">Password must not be empty.</b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.form.confirmPassword.isSame">Passwords do not match.</b-form-invalid-feedback>
          </b-form-group>
        </b-row>
        <b-row>
          <b-button
            variant="primary"
            class="default-btn mb-3 flex-1"
            type="submit"
          >
            Reset Password
          </b-button>
        </b-row>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Logo from "@/components/shared/Logo.vue";

export default {
  name: "ChangePassword",
  components: { Logo },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        pattern(value) {
          if (value) return this.$store.state.users.passwordRegex.test(value);
          return true;
        }
      },
      confirmPassword: {
        required,
        isSame(value) {
          return this.$v.form.password.$model === value;
        }
      }
    }
  },
  data() {
    return {
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  mounted() {
    this.$store.commit("SHOW_HEADER", false);
  },
  methods: {
    validateState(name, validate = false) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    touchInvalidControls() {
      Object.keys(this.$v.form)
        .filter(key => key.indexOf("$") === -1)
        .forEach(key => {
          const control = this.$v.form[key];
          if (control.$invalid) {
            control.$touch();
          }
        });
    },
    isFormValid() {
      const isValid = !this.$v.form.$invalid;
      if (!isValid) this.touchInvalidControls();
      return isValid;
    },
    save() {
      if (this.isFormValid()) {
        this.$store.dispatch("handleUpdateForgottenPassword", {
          password: this.form.password
        });
      }
    }
  }
};
</script>

<style lang="scss">
.page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/images/earth_background.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  .change-password-form{
    .custom-field-wrapper{
      height: 100px;
    }
  }
  .page-form {
    border-radius: 8px;
    padding: 10px 20px;
    background-color: #212c38;
    overflow: hidden;
    width: 500px;
    .logo {
      color: #ced6e0;
      .logo-header-text {
        font-size: 16px;
      }
      .powered-by-text {
        margin-top: -5px;
        font-size: 12px;
      }
    }
    .header-title {
      font-size: 32px;
    }
  }
}
</style>
